.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

footer {
  margin-top: 100px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.titles {
  font-size: 50px;
  font-weight: bold;
}

.App-link {
  color: #61dafb;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;

  margin-top: 5vh;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(112, 112, 112, 0.202);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.coming-soon {
  text-align: center;
}
.nav-link {
  margin-right: 50px;
}

.navbar-nav {
  flex-direction: row;
  justify-content: space-between;
  margin-right: 50px;
}

hr {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

p {
  width: 40%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f1f1f1;
}

/* .logo img {
  width: 100px; /* Adjust the width as needed */

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin-right: 1rem;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.join-us button {
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.animation {
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
}

.animations {
  height: 300px;
  width: 300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.welcome {
  margin: 100px;
  margin-bottom: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
